import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["game", "click"];

  connect() {
    // this.click = this.click.bind(this);
    // document.addEventListener("click", this.click, true);
  }

  disconnect() {
    document.removeEventListener("click", this.click, true);
  }

  click(event) {
    const insideGame = this.gameTarget.contains(event.target);
    const isClickableElement = ["BUTTON", "A"].some(
      (tag) => event.target.tagName === tag
    );
    const disabled = event.target.getAttribute("disabled");
    if (!insideGame || !isClickableElement || disabled) return;

    new Promise(() => {
      const clickSound = this.clickTarget.cloneNode();
      clickSound.play();
      clickSound.remove();
    });
  }
}
